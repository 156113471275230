import React from "react";
import importantDates from "./importantDates";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { motion } from "framer-motion";

type Props = {
  date: Date;
};

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const styles = {
  normal: {
    background: "#ffffff",
    color: "#3e4e68",
  },
  hover: {
    background: "#d8d8d88c",
    color: "#3e4e68",
  },
  today: {
    background: "#40ab62",
    color: "#ffffff",
  },
  event: {
    background: "#fff1d6",
    color: "#3e4e68",
  },
};

export default function Day(props: Props) {
  const date = props.date;
  const highlights = importantDates.filter(
    (d) => d.miliseconds === date.getTime()
  );
  let style = styles.normal;
  if (highlights.length > 0) style = styles.event;  
  if (date.getTime() === today.getTime()) style = styles.today;  
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
    >
      <Tippy
        content={highlights.map((highlight) => highlight.name).join(", ")}
        disabled={highlights.length === 0}
      >
        <motion.span
          whileHover={{ scale: 1.2, ...styles.hover }}
          animate={{ ...style }}
          className="day-container"
          key={date.getDate()}
        >
          {date.getDate()}
        </motion.span>
      </Tippy>
    </motion.div>
  );
}

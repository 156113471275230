import {
  WeekCalculation,
  WeekGrouping,
  LastDayOfWeek,
  LastMonthOfYear,
  //LeapYearStrategy,
} from "retail-calendar";

const calendarSettings = [
  {
    retailer: "walmart",
    weekCalculation: WeekCalculation.LastDayBeforeEOM,
    weekGrouping: WeekGrouping.Group454,
    lastDayOfWeek: LastDayOfWeek.Friday,
    lastMonthOfYear: LastMonthOfYear.January,
    //leapYearStrategy: LeapYearStrategy.DropLastWeek,
    //restated: false,
  },

  {
    retailer: "target",
    weekCalculation: WeekCalculation.LastDayNearestEOM,
    weekGrouping: WeekGrouping.Group454,
    lastDayOfWeek: LastDayOfWeek.Saturday,
    lastMonthOfYear: LastMonthOfYear.January,
    //leapYearStrategy: LeapYearStrategy.DropLastWeek,
    //restated: false,
  },

  {
    retailer: "kroger",
    weekCalculation: WeekCalculation.LastDayNearestEOM,
    weekGrouping: WeekGrouping.Group454,
    lastDayOfWeek: LastDayOfWeek.Saturday,
    lastMonthOfYear: LastMonthOfYear.January,
    //leapYearStrategy: LeapYearStrategy.DropLastWeek,
    //restated: false,
  },
];

export default calendarSettings;

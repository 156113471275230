import React from "react";
import { RetailCalendarFactory } from "retail-calendar";
import calendarSettings from "./calendarSettings";
import Month from "./Month";
import {
  CalendarType,
  CalendaView,
  RetailCalendarMonthCustom,
} from "./types";

type Props = {
  year: number;
  handleViewChange: (e: React.MouseEvent<HTMLElement>) => void;
  selectedView: CalendaView;
  selectedCalendar: CalendarType;
};



export default function Year(props: Props) {

  const calendarSetting = calendarSettings.find(setting => setting.retailer === props.selectedCalendar);
  let calendar: any;
  if (calendarSetting) {
    calendar = new RetailCalendarFactory(
      {
        weekCalculation: calendarSetting.weekCalculation,
        weekGrouping: calendarSetting.weekGrouping,
        lastDayOfWeek: calendarSetting.lastDayOfWeek,
        lastMonthOfYear: calendarSetting.lastMonthOfYear,
        //leapYearStrategy: calendarSetting.leapYearStrategy,
      },
      props.year
    );
  }

  const months = calendar.months as RetailCalendarMonthCustom[];
  const monthItem = months.map((item) => (
    <Month
      key={item.monthOfYear}
      month={item}
      year={item.year}
      calendar={calendar}
      selectedView={props.selectedView}
      handleViewChange={props.handleViewChange}
      selectedCalendar={props.selectedCalendar}
    />
  ));

  if (props.selectedView === CalendaView.year) return <div className="single-year-month">{monthItem}</div>;
  else if (props.selectedView === CalendaView.multiYear) return <div className="multi-year-month">{monthItem}</div>;
  else return <h1> Unknown View </h1>;

}

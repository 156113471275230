import React from "react";
import Day from "./Day";

type WeekProps = {
  startDate: Date;
  endDate: Date;
  weekOfYear: number;
  numberOfWeeksInMonth: number;
  weekOfMonth: number;
};


export default function Week(props: WeekProps) {
  const week = props.weekOfYear === -1 ? 53 : props.weekOfYear + 1;
  const dates = [];
  let classes = "week-container";
  for (let i = 0; i < 7; i++) {
    const date = new Date(props.startDate);
    date.setDate(date.getDate() + i);
    dates.push(date);
  }
  if (props.weekOfMonth + 1 === props.numberOfWeeksInMonth)
    classes += " last-week-in-month";
  return (
    <div className={classes}>
      <p className="week-number">{week}</p>
      {dates.map((d) => (
        <Day key={d.toDateString()} date={d} />
      ))}
    </div>
  );
  
}

import React from "react";
import Calendar from "./Calendar";
import Footer from "./Footer";
import "./App.scss";
import 'iframe-resizer/js/iframeResizer.contentWindow';

export default function App() {
  let isIframed = window.top !== window;
  if (isIframed) {
    return (
      <div className="App">
        <Calendar />

      </div>
    );
  } else {
    return (
      <div className="App">
        <Calendar />
        <Footer />
      </div>
    );
  }
}

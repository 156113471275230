const importantDates = [
  {
    miliseconds: 1604206800000,
    year: 2020,
    month: 10,
    day: 1,
    name: "Daylight Saving Time ends",
  },
  {
    miliseconds: 1604383200000,
    year: 2020,
    month: 10,
    day: 3,
    name: "Election Day",
  },
  {
    miliseconds: 1605074400000,
    year: 2020,
    month: 10,
    day: 11,
    name: "Veterans Day",
  },
  {
    miliseconds: 1606370400000,
    year: 2020,
    month: 10,
    day: 26,
    name: "Thanksgiving Day",
  },
  {
    miliseconds: 1606456800000,
    year: 2020,
    month: 10,
    day: 27,
    name: "Black Friday",
  },
  {
    miliseconds: 1606716000000,
    year: 2020,
    month: 10,
    day: 30,
    name: "Cyber Monday",
  },
  {
    miliseconds: 1607666400000,
    year: 2020,
    month: 11,
    day: 11,
    name: "Chanukah/Hanukkah (first day)",
  },
  {
    miliseconds: 1608271200000,
    year: 2020,
    month: 11,
    day: 18,
    name: "Last Day of Chanukah",
  },
  {
    miliseconds: 1608530400000,
    year: 2020,
    month: 11,
    day: 21,
    name: "Winter Solstice",
  },
  {
    miliseconds: 1608789600000,
    year: 2020,
    month: 11,
    day: 24,
    name: "Christmas Eve",
  },
  {
    miliseconds: 1608876000000,
    year: 2020,
    month: 11,
    day: 25,
    name: "Christmas Day",
  },
  {
    miliseconds: 1608962400000,
    year: 2020,
    month: 11,
    day: 26,
    name: "Kwanzaa (first day)",
  },
  {
    miliseconds: 1609394400000,
    year: 2020,
    month: 11,
    day: 31,
    name: "New Year's Eve",
  },
  {
    miliseconds: 1609480800000,
    year: 2021,
    month: 0,
    day: 1,
    name: "New Year's Day",
  },
  {
    miliseconds: 1609480800000,
    year: 2021,
    month: 0,
    day: 1,
    name: "Kwanzaa (last day)",
  },
  {
    miliseconds: 1610949600000,
    year: 2021,
    month: 0,
    day: 18,
    name: "Martin Luther King Jr. Day",
  },
  {
    miliseconds: 1611122400000,
    year: 2021,
    month: 0,
    day: 20,
    name: "Inauguration Day",
  },
  {
    miliseconds: 1612245600000,
    year: 2021,
    month: 1,
    day: 2,
    name: "Groundhog Day",
  },
  {
    miliseconds: 1612677600000,
    year: 2021,
    month: 1,
    day: 7,
    name: "Super Bowl",
  },
  {
    miliseconds: 1613109600000,
    year: 2021,
    month: 1,
    day: 12,
    name: "Chinese New Year",
  },
  {
    miliseconds: 1613282400000,
    year: 2021,
    month: 1,
    day: 14,
    name: "Valentine's Day",
  },
  {
    miliseconds: 1613368800000,
    year: 2021,
    month: 1,
    day: 15,
    name: "Presidents' Day",
  },
  {
    miliseconds: 1613455200000,
    year: 2021,
    month: 1,
    day: 16,
    name: "Mardi Gras",
  },
  {
    miliseconds: 1613541600000,
    year: 2021,
    month: 1,
    day: 17,
    name: "Ash Wednesday",
  },
  {
    miliseconds: 1615701600000,
    year: 2021,
    month: 2,
    day: 14,
    name: "Daylight Savings Time starts",
  },
  {
    miliseconds: 1615957200000,
    year: 2021,
    month: 2,
    day: 17,
    name: "St. Patrick's Day",
  },
  {
    miliseconds: 1616216400000,
    year: 2021,
    month: 2,
    day: 20,
    name: "Spring Equinox",
  },
  {
    miliseconds: 1616907600000,
    year: 2021,
    month: 2,
    day: 28,
    name: "Palm Sunday",
  },
  {
    miliseconds: 1616907600000,
    year: 2021,
    month: 2,
    day: 28,
    name: "Passover (first day)",
  },
  {
    miliseconds: 1617253200000,
    year: 2021,
    month: 3,
    day: 1,
    name: "April Fool's Day",
  },
  {
    miliseconds: 1617339600000,
    year: 2021,
    month: 3,
    day: 2,
    name: "Good Friday",
  },
  {
    miliseconds: 1617512400000,
    year: 2021,
    month: 3,
    day: 4,
    name: "Last Day of Passover",
  },
  {
    miliseconds: 1617512400000,
    year: 2021,
    month: 3,
    day: 4,
    name: "Easter Sunday",
  },
  {
    miliseconds: 1618290000000,
    year: 2021,
    month: 3,
    day: 13,
    name: "First Day of Ramadan",
  },
  {
    miliseconds: 1618462800000,
    year: 2021,
    month: 3,
    day: 15,
    name: "Tax Day",
  },
  {
    miliseconds: 1619067600000,
    year: 2021,
    month: 3,
    day: 22,
    name: "Earth Day",
  },
  {
    miliseconds: 1620190800000,
    year: 2021,
    month: 4,
    day: 5,
    name: "Cinco de Mayo",
  },
  {
    miliseconds: 1620450000000,
    year: 2021,
    month: 4,
    day: 8,
    name: "Lailat al-Qadr",
  },
  {
    miliseconds: 1620536400000,
    year: 2021,
    month: 4,
    day: 9,
    name: "Mother's Day",
  },
  {
    miliseconds: 1620882000000,
    year: 2021,
    month: 4,
    day: 13,
    name: "Eid al-Fitr",
  },
  {
    miliseconds: 1622437200000,
    year: 2021,
    month: 4,
    day: 31,
    name: "Memorial Day",
  },
  {
    miliseconds: 1623646800000,
    year: 2021,
    month: 5,
    day: 14,
    name: "Flag Day",
  },
  {
    miliseconds: 1624078800000,
    year: 2021,
    month: 5,
    day: 19,
    name: "Juneteenth",
  },
  {
    miliseconds: 1624165200000,
    year: 2021,
    month: 5,
    day: 20,
    name: "Father's Day",
  },
  {
    miliseconds: 1624165200000,
    year: 2021,
    month: 5,
    day: 20,
    name: "Summer Solstice",
  },
  {
    miliseconds: 1625374800000,
    year: 2021,
    month: 6,
    day: 4,
    name: "Independence Day",
  },
  {
    miliseconds: 1626757200000,
    year: 2021,
    month: 6,
    day: 20,
    name: "Eid al-Adha",
  },
  {
    miliseconds: 1630904400000,
    year: 2021,
    month: 8,
    day: 6,
    name: "Labor Day",
  },
  {
    miliseconds: 1630990800000,
    year: 2021,
    month: 8,
    day: 7,
    name: "Rosh Hashanah",
  },
  {
    miliseconds: 1631768400000,
    year: 2021,
    month: 8,
    day: 16,
    name: "Yom Kippur",
  },
  {
    miliseconds: 1632286800000,
    year: 2021,
    month: 8,
    day: 22,
    name: "Autumn Equinox",
  },
  {
    miliseconds: 1633928400000,
    year: 2021,
    month: 9,
    day: 11,
    name: "Indigenous People's Day",
  },
  {
    miliseconds: 1635656400000,
    year: 2021,
    month: 9,
    day: 31,
    name: "Halloween",
  },
  {
    miliseconds: 1635829200000,
    year: 2021,
    month: 10,
    day: 2,
    name: "Election Day",
  },
  {
    miliseconds: 1636261200000,
    year: 2021,
    month: 10,
    day: 7,
    name: "Daylight Savings Time ends",
  },
  {
    miliseconds: 1636610400000,
    year: 2021,
    month: 10,
    day: 11,
    name: "Veterans Day",
  },
  {
    miliseconds: 1637820000000,
    year: 2021,
    month: 10,
    day: 25,
    name: "Thanksgiving Day",
  },
  {
    miliseconds: 1637906400000,
    year: 2021,
    month: 10,
    day: 26,
    name: "Black Friday",
  },
  {
    miliseconds: 1638165600000,
    year: 2021,
    month: 10,
    day: 29,
    name: "Cyber Monday",
  },
  {
    miliseconds: 1638165600000,
    year: 2021,
    month: 10,
    day: 29,
    name: "First Day of Hanukkah",
  },
  {
    miliseconds: 1638770400000,
    year: 2021,
    month: 11,
    day: 6,
    name: "Last Day of Hanukkah",
  },
  {
    miliseconds: 1640066400000,
    year: 2021,
    month: 11,
    day: 21,
    name: "Winter Solstice",
  },
  {
    miliseconds: 1640325600000,
    year: 2021,
    month: 11,
    day: 24,
    name: "Christmas Eve",
  },
  {
    miliseconds: 1640412000000,
    year: 2021,
    month: 11,
    day: 25,
    name: "Christmas Day",
  },
  {
    miliseconds: 1640498400000,
    year: 2021,
    month: 11,
    day: 26,
    name: "Kwanzaa (first day)",
  },
  {
    miliseconds: 1640930400000,
    year: 2021,
    month: 11,
    day: 31,
    name: "New Year's Eve",
  },
  {
    miliseconds: 1641016800000,
    year: 2022,
    month: 0,
    day: 1,
    name: "New Year's Day",
  },
  {
    miliseconds: 1641016800000,
    year: 2022,
    month: 0,
    day: 1,
    name: "Kwanzaa (last day)",
  },
  {
    miliseconds: 1642399200000,
    year: 2022,
    month: 0,
    day: 17,
    name: "Martin Luther King Jr. Day",
  },
  {
    miliseconds: 1643695200000,
    year: 2022,
    month: 1,
    day: 1,
    name: "Chinese New Year",
  },
  {
    miliseconds: 1643781600000,
    year: 2022,
    month: 1,
    day: 2,
    name: "Groundhog Day",
  },
  {
    miliseconds: 1644127200000,
    year: 2022,
    month: 1,
    day: 6,
    name: "Super Bowl",
  },
  {
    miliseconds: 1644818400000,
    year: 2022,
    month: 1,
    day: 14,
    name: "Valentine's Day",
  },
  {
    miliseconds: 1645423200000,
    year: 2022,
    month: 1,
    day: 21,
    name: "Presidents' Day",
  },
  {
    miliseconds: 1646114400000,
    year: 2022,
    month: 2,
    day: 1,
    name: "Mardi Gras",
  },
  {
    miliseconds: 1646200800000,
    year: 2022,
    month: 2,
    day: 2,
    name: "Ash Wednesday",
  },
  {
    miliseconds: 1647151200000,
    year: 2022,
    month: 2,
    day: 13,
    name: "Daylight Saving Time starts",
  },
  {
    miliseconds: 1647493200000,
    year: 2022,
    month: 2,
    day: 17,
    name: "St. Patrick's Day",
  },
  {
    miliseconds: 1647752400000,
    year: 2022,
    month: 2,
    day: 20,
    name: "Spring Equinox",
  },
  {
    miliseconds: 1648789200000,
    year: 2022,
    month: 3,
    day: 1,
    name: "April Fool's Day",
  },
  {
    miliseconds: 1648962000000,
    year: 2022,
    month: 3,
    day: 3,
    name: "First Day of Ramadan",
  },
  {
    miliseconds: 1649566800000,
    year: 2022,
    month: 3,
    day: 10,
    name: "Palm Sunday",
  },
  {
    miliseconds: 1649998800000,
    year: 2022,
    month: 3,
    day: 15,
    name: "Good Friday",
  },
  {
    miliseconds: 1650085200000,
    year: 2022,
    month: 3,
    day: 16,
    name: "Passover (first day)",
  },
  {
    miliseconds: 1650171600000,
    year: 2022,
    month: 3,
    day: 17,
    name: "Easter Sunday",
  },
  {
    miliseconds: 1650258000000,
    year: 2022,
    month: 3,
    day: 18,
    name: "Tax Day",
  },
  {
    miliseconds: 1650603600000,
    year: 2022,
    month: 3,
    day: 22,
    name: "Earth Day",
  },
  {
    miliseconds: 1650690000000,
    year: 2022,
    month: 3,
    day: 23,
    name: "Last Day of Passover",
  },
  {
    miliseconds: 1651122000000,
    year: 2022,
    month: 3,
    day: 28,
    name: "Lailat al-Qadr",
  },
  {
    miliseconds: 1651554000000,
    year: 2022,
    month: 4,
    day: 3,
    name: "Eid al-Fitr",
  },
  {
    miliseconds: 1651726800000,
    year: 2022,
    month: 4,
    day: 5,
    name: "Cinco de Mayo",
  },
  {
    miliseconds: 1651986000000,
    year: 2022,
    month: 4,
    day: 8,
    name: "Mother's Day",
  },
  {
    miliseconds: 1653886800000,
    year: 2022,
    month: 4,
    day: 30,
    name: "Memorial Day",
  },
  {
    miliseconds: 1655182800000,
    year: 2022,
    month: 5,
    day: 14,
    name: "Flag Day",
  },
  {
    miliseconds: 1655614800000,
    year: 2022,
    month: 5,
    day: 19,
    name: "Father's Day",
  },
  {
    miliseconds: 1655614800000,
    year: 2022,
    month: 5,
    day: 19,
    name: "Juneteenth",
  },
  {
    miliseconds: 1655787600000,
    year: 2022,
    month: 5,
    day: 21,
    name: "Summer Solstice",
  },
  {
    miliseconds: 1656910800000,
    year: 2022,
    month: 6,
    day: 4,
    name: "Independence Day",
  },
  {
    miliseconds: 1657429200000,
    year: 2022,
    month: 6,
    day: 10,
    name: "Eid al-Adha",
  },
  {
    miliseconds: 1662354000000,
    year: 2022,
    month: 8,
    day: 5,
    name: "Labor Day",
  },
  {
    miliseconds: 1663822800000,
    year: 2022,
    month: 8,
    day: 22,
    name: "Autumn Equinox",
  },
  {
    miliseconds: 1664168400000,
    year: 2022,
    month: 8,
    day: 26,
    name: "Rosh Hashana",
  },
  {
    miliseconds: 1664946000000,
    year: 2022,
    month: 9,
    day: 5,
    name: "Yom Kippur",
  },
  {
    miliseconds: 1665378000000,
    year: 2022,
    month: 9,
    day: 10,
    name: "Indigenous People's Day",
  },
  {
    miliseconds: 1667192400000,
    year: 2022,
    month: 9,
    day: 31,
    name: "Halloween",
  },
  {
    miliseconds: 1667710800000,
    year: 2022,
    month: 10,
    day: 6,
    name: "Daylight Saving Time ends",
  },
  {
    miliseconds: 1667887200000,
    year: 2022,
    month: 10,
    day: 8,
    name: "Election Day",
  },
  {
    miliseconds: 1668146400000,
    year: 2022,
    month: 10,
    day: 11,
    name: "Veterans Day",
  },
  {
    miliseconds: 1669269600000,
    year: 2022,
    month: 10,
    day: 24,
    name: "Thanksgiving Day",
  },
  {
    miliseconds: 1669356000000,
    year: 2022,
    month: 10,
    day: 25,
    name: "Black Friday",
  },
  {
    miliseconds: 1669615200000,
    year: 2022,
    month: 10,
    day: 28,
    name: "Cyber Monday",
  },
  {
    miliseconds: 1671429600000,
    year: 2022,
    month: 11,
    day: 19,
    name: "Chanukah/Hanukkah (first day)",
  },
  {
    miliseconds: 1671602400000,
    year: 2022,
    month: 11,
    day: 21,
    name: "Winter Solstice",
  },
  {
    miliseconds: 1671861600000,
    year: 2022,
    month: 11,
    day: 24,
    name: "Christmas Eve",
  },
  {
    miliseconds: 1671948000000,
    year: 2022,
    month: 11,
    day: 25,
    name: "Christmas Day",
  },
  {
    miliseconds: 1672034400000,
    year: 2022,
    month: 11,
    day: 26,
    name: "Last Day of Chanukah",
  },
  {
    miliseconds: 1672034400000,
    year: 2022,
    month: 11,
    day: 26,
    name: "Kwanzaa (first day)",
  },
  {
    miliseconds: 1672466400000,
    year: 2022,
    month: 11,
    day: 31,
    name: "New Year's Eve",
  },
  {
    miliseconds: 1672552800000,
    year: 2023,
    month: 0,
    day: 1,
    name: "New Year's Day",
  },
  {
    miliseconds: 1672552800000,
    year: 2023,
    month: 0,
    day: 1,
    name: "Kwanzaa Ends",
  },
  {
    miliseconds: 1673848800000,
    year: 2023,
    month: 0,
    day: 16,
    name: "Martin Luther King Jr. Day",
  },
  {
    miliseconds: 1674367200000,
    year: 2023,
    month: 0,
    day: 22,
    name: "Chinese New Year",
  },
  {
    miliseconds: 1675317600000,
    year: 2023,
    month: 1,
    day: 2,
    name: "Groundhog Day",
  },
  {
    miliseconds: 1676181600000,
    year: 2023,
    month: 1,
    day: 12,
    name: "Super Bowl",
  },
  {
    miliseconds: 1676354400000,
    year: 2023,
    month: 1,
    day: 14,
    name: "Valentine's Day",
  },
  {
    miliseconds: 1676872800000,
    year: 2023,
    month: 1,
    day: 20,
    name: "Presidents' Day",
  },
  {
    miliseconds: 1676959200000,
    year: 2023,
    month: 1,
    day: 21,
    name: "Mardi Gras",
  },
  {
    miliseconds: 1677045600000,
    year: 2023,
    month: 1,
    day: 22,
    name: "Ash Wednesday",
  },
  {
    miliseconds: 1678600800000,
    year: 2023,
    month: 2,
    day: 12,
    name: "Daylight Savings Time Begins",
  },
  {
    miliseconds: 1679029200000,
    year: 2023,
    month: 2,
    day: 17,
    name: "St. Patrick's Day",
  },
  {
    miliseconds: 1679288400000,
    year: 2023,
    month: 2,
    day: 20,
    name: "Spring Equinox",
  },
  {
    miliseconds: 1679461200000,
    year: 2023,
    month: 2,
    day: 22,
    name: "Ramadan Begins",
  },
  {
    miliseconds: 1680325200000,
    year: 2023,
    month: 3,
    day: 1,
    name: "April Fools' Day",
  },
  {
    miliseconds: 1680411600000,
    year: 2023,
    month: 3,
    day: 2,
    name: "Palm Sunday",
  },
  {
    miliseconds: 1680757200000,
    year: 2023,
    month: 3,
    day: 6,
    name: "Passover Begins",
  },
  {
    miliseconds: 1680843600000,
    year: 2023,
    month: 3,
    day: 7,
    name: "Good Friday",
  },
  {
    miliseconds: 1681016400000,
    year: 2023,
    month: 3,
    day: 9,
    name: "Easter Sunday",
  },
  {
    miliseconds: 1681362000000,
    year: 2023,
    month: 3,
    day: 13,
    name: "Passover Ends",
  },
  {
    miliseconds: 1681707600000,
    year: 2023,
    month: 3,
    day: 17,
    name: "Laylat al-Qadr",
  },
  {
    miliseconds: 1681794000000,
    year: 2023,
    month: 3,
    day: 18,
    name: "Tax Day",
  },
  {
    miliseconds: 1682053200000,
    year: 2023,
    month: 3,
    day: 21,
    name: "Ramadan Ends",
  },
  {
    miliseconds: 1682139600000,
    year: 2023,
    month: 3,
    day: 22,
    name: "Eid al-Fitr (tentative date)",
  },
  {
    miliseconds: 1682139600000,
    year: 2023,
    month: 3,
    day: 22,
    name: "Earth Day",
  },
  {
    miliseconds: 1683262800000,
    year: 2023,
    month: 4,
    day: 5,
    name: "Cinco de Mayo",
  },
  {
    miliseconds: 1684040400000,
    year: 2023,
    month: 4,
    day: 14,
    name: "Mother's Day",
  },
  {
    miliseconds: 1685336400000,
    year: 2023,
    month: 4,
    day: 29,
    name: "Memorial Day",
  },
  {
    miliseconds: 1686718800000,
    year: 2023,
    month: 5,
    day: 14,
    name: "Flag Day",
  },
  {
    miliseconds: 1687064400000,
    year: 2023,
    month: 5,
    day: 18,
    name: "Father's Day",
  },
  {
    miliseconds: 1687150800000,
    year: 2023,
    month: 5,
    day: 19,
    name: "Juneteenth",
  },
  {
    miliseconds: 1687323600000,
    year: 2023,
    month: 5,
    day: 21,
    name: "Summer Solstice",
  },
  {
    miliseconds: 1688014800000,
    year: 2023,
    month: 5,
    day: 29,
    name: "Eid al-Adha (tentative date)",
  },
  {
    miliseconds: 1688446800000,
    year: 2023,
    month: 6,
    day: 4,
    name: "Independence Day",
  },
  {
    miliseconds: 1693803600000,
    year: 2023,
    month: 8,
    day: 4,
    name: "Labor Day",
  },
  {
    miliseconds: 1694840400000,
    year: 2023,
    month: 8,
    day: 16,
    name: "Rosh Hashanah",
  },
  {
    miliseconds: 1695445200000,
    year: 2023,
    month: 8,
    day: 23,
    name: "Autumn Equinox ",
  },
  {
    miliseconds: 1695618000000,
    year: 2023,
    month: 8,
    day: 25,
    name: "Yom Kippur",
  },
  {
    miliseconds: 1696827600000,
    year: 2023,
    month: 9,
    day: 9,
    name: "Indigenous People's Day ",
  },
  {
    miliseconds: 1698728400000,
    year: 2023,
    month: 9,
    day: 31,
    name: "Halloween",
  },
  {
    miliseconds: 1699160400000,
    year: 2023,
    month: 10,
    day: 5,
    name: "Daylight Savings Time ends",
  },
  {
    miliseconds: 1699336800000,
    year: 2023,
    month: 10,
    day: 7,
    name: "Election Day",
  },
  {
    miliseconds: 1699682400000,
    year: 2023,
    month: 10,
    day: 11,
    name: "Veterans Day",
  },
  {
    miliseconds: 1700719200000,
    year: 2023,
    month: 10,
    day: 23,
    name: "Thanksgiving Day",
  },
  {
    miliseconds: 1700805600000,
    year: 2023,
    month: 10,
    day: 24,
    name: "Black Friday ",
  },
  {
    miliseconds: 1701064800000,
    year: 2023,
    month: 10,
    day: 27,
    name: "Cyber Monday",
  },
  {
    miliseconds: 1702015200000,
    year: 2023,
    month: 11,
    day: 8,
    name: "First Day of Hanukkah",
  },
  {
    miliseconds: 1702620000000,
    year: 2023,
    month: 11,
    day: 15,
    name: "Last Day of Hanukkah",
  },
  {
    miliseconds: 1703138400000,
    year: 2023,
    month: 11,
    day: 21,
    name: "Winter Solstice",
  },
  {
    miliseconds: 1703397600000,
    year: 2023,
    month: 11,
    day: 24,
    name: "Christmas Eve",
  },
  {
    miliseconds: 1703484000000,
    year: 2023,
    month: 11,
    day: 25,
    name: "Christmas Day",
  },
  {
    miliseconds: 1703570400000,
    year: 2023,
    month: 11,
    day: 26,
    name: "Kwanzaa Begins",
  },
  {
    miliseconds: 1704002400000,
    year: 2023,
    month: 11,
    day: 31,
    name: "New Year's Eve",
  },
  {
    miliseconds: 1704088800000,
    year: 2024,
    month: 0,
    day: 1,
    name: "New Year's Day",
  },
  {
    miliseconds: 1704088800000,
    year: 2024,
    month: 0,
    day: 1,
    name: "Kwanzaa Ends",
  },
  {
    miliseconds: 1705298400000,
    year: 2024,
    month: 0,
    day: 15,
    name: "Martin Luther King Jr. Day",
  },
  {
    miliseconds: 1707544800000,
    year: 2024,
    month: 1,
    day: 10,
    name: "Chinese New Year",
  },
  {
    miliseconds: 1706853600000,
    year: 2024,
    month: 1,
    day: 2,
    name: "Groundhog Day",
  },
  {
    miliseconds: 1707631200000,
    year: 2024,
    month: 1,
    day: 11,
    name: "Super Bowl",
  },
  {
    miliseconds: 1707890400000,
    year: 2024,
    month: 1,
    day: 14,
    name: "Valentine's Day",
  },
  {
    miliseconds: 1708322400000,
    year: 2024,
    month: 1,
    day: 19,
    name: "Presidents' Day",
  },
  {
    miliseconds: 1707804000000,
    year: 2024,
    month: 1,
    day: 13,
    name: "Mardi Gras",
  },
  {
    miliseconds: 1707890400000,
    year: 2024,
    month: 1,
    day: 14,
    name: "Ash Wednesday",
  },
  {
    miliseconds: 1710050400000,
    year: 2024,
    month: 2,
    day: 10,
    name: "Daylight Savings Time Begins",
  },
  {
    miliseconds: 1710651600000,
    year: 2024,
    month: 2,
    day: 17,
    name: "St. Patrick's Day",
  },
  {
    miliseconds: 1710824400000,
    year: 2024,
    month: 2,
    day: 19,
    name: "Spring Equinox",
  },
  {
    miliseconds: 1710050400000,
    year: 2024,
    month: 2,
    day: 10,
    name: "Ramadan Begins",
  },
  {
    miliseconds: 1711947600000,
    year: 2024,
    month: 3,
    day: 1,
    name: "April Fools' Day",
  },
  {
    miliseconds: 1711256400000,
    year: 2024,
    month: 2,
    day: 24,
    name: "Palm Sunday",
  },
  {
    miliseconds: 1713762000000,
    year: 2024,
    month: 3,
    day: 22,
    name: "Passover Begins",
  },
  {
    miliseconds: 1711688400000,
    year: 2024,
    month: 2,
    day: 29,
    name: "Good Friday",
  },
  {
    miliseconds: 1711861200000,
    year: 2024,
    month: 2,
    day: 31,
    name: "Easter Sunday",
  },
  {
    miliseconds: 1714453200000,
    year: 2024,
    month: 3,
    day: 30,
    name: "Passover Ends",
  },
  {
    miliseconds: 1714107600000,
    year: 2024,
    month: 3,
    day: 26,
    name: "Laylat al-Qadr",
  },
  {
    miliseconds: 1713157200000,
    year: 2024,
    month: 3,
    day: 15,
    name: "Tax Day",
  },
  {
    miliseconds: 1712638800000,
    year: 2024,
    month: 3,
    day: 9,
    name: "Ramadan Ends",
  },
  {
    miliseconds: 1713762000000,
    year: 2024,
    month: 3,
    day: 22,
    name: "Earth Day",
  },
  {
    miliseconds: 1712811600000,
    year: 2024,
    month: 3,
    day: 11,
    name: "Eid al-Fitr (tentative date)",
  },
  {
    miliseconds: 1714885200000,
    year: 2024,
    month: 4,
    day: 5,
    name: "Cinco de Mayo",
  },
  {
    miliseconds: 1715490000000,
    year: 2024,
    month: 4,
    day: 12,
    name: "Mother's Day",
  },
  {
    miliseconds: 1716786000000,
    year: 2024,
    month: 4,
    day: 27,
    name: "Memorial Day",
  },
  {
    miliseconds: 1718341200000,
    year: 2024,
    month: 5,
    day: 14,
    name: "Flag Day",
  },
  {
    miliseconds: 1718514000000,
    year: 2024,
    month: 5,
    day: 16,
    name: "Father's Day",
  },
  {
    miliseconds: 1718773200000,
    year: 2024,
    month: 5,
    day: 19,
    name: "Juneteenth",
  },
  {
    miliseconds: 1718859600000,
    year: 2024,
    month: 5,
    day: 20,
    name: "Summer Solstice",
  },
  {
    miliseconds: 1718514000000,
    year: 2024,
    month: 5,
    day: 16,
    name: "Eid al-Adha (tentative date)",
  },
  {
    miliseconds: 1720069200000,
    year: 2024,
    month: 6,
    day: 4,
    name: "Independence Day",
  },
  {
    miliseconds: 1725253200000,
    year: 2024,
    month: 8,
    day: 2,
    name: "Labor Day",
  },
  {
    miliseconds: 1727845200000,
    year: 2024,
    month: 9,
    day: 2,
    name: "Rosh Hashanah",
  },
  {
    miliseconds: 1726981200000,
    year: 2024,
    month: 8,
    day: 22,
    name: "Autumn Equinox ",
  },
  {
    miliseconds: 1728622800000,
    year: 2024,
    month: 9,
    day: 11,
    name: "Yom Kippur",
  },
  {
    miliseconds: 1728882000000,
    year: 2024,
    month: 9,
    day: 14,
    name: "Indigenous People's Day ",
  },
  {
    miliseconds: 1730350800000,
    year: 2024,
    month: 9,
    day: 31,
    name: "Halloween",
  },
  {
    miliseconds: 1730610000000,
    year: 2024,
    month: 10,
    day: 3,
    name: "Daylight Savings Time ends",
  },
  {
    miliseconds: 1730786400000,
    year: 2024,
    month: 10,
    day: 5,
    name: "Election Day",
  },
  {
    miliseconds: 1731304800000,
    year: 2024,
    month: 10,
    day: 11,
    name: "Veterans Day",
  },
  {
    miliseconds: 1732773600000,
    year: 2024,
    month: 10,
    day: 28,
    name: "Thanksgiving Day",
  },
  {
    miliseconds: 1732860000000,
    year: 2024,
    month: 10,
    day: 29,
    name: "Black Friday ",
  },
  {
    miliseconds: 1733119200000,
    year: 2024,
    month: 11,
    day: 2,
    name: "Cyber Monday",
  },
  {
    miliseconds: 1735106400000,
    year: 2024,
    month: 11,
    day: 25,
    name: "First Day of Hanukkah",
  },
  {
    miliseconds: 1735797600000,
    year: 2025,
    month: 0,
    day: 2,
    name: "Last Day of Hanukkah",
  },
  {
    miliseconds: 1734760800000,
    year: 2024,
    month: 11,
    day: 21,
    name: "Winter Solstice",
  },
  {
    miliseconds: 1735020000000,
    year: 2024,
    month: 11,
    day: 24,
    name: "Christmas Eve",
  },
  {
    miliseconds: 1735106400000,
    year: 2024,
    month: 11,
    day: 25,
    name: "Christmas Day",
  },
  {
    miliseconds: 1735192800000,
    year: 2024,
    month: 11,
    day: 26,
    name: "Kwanzaa Begins",
  },
  {
    miliseconds: 1735624800000,
    year: 2024,
    month: 11,
    day: 31,
    name: "New Year's Eve",
  },
];

export default importantDates;

import React from "react";
import { CalendaView } from "./types";

type MonthHeaderProps = {
  monthOfYear: string;
  calendarYear: number;
  monthName: string;
  quarterOfYear: number;
  handleViewChange: any;
  handleMonthChange?: any;
  selectedView: CalendaView;
};

export default function MonthHeader(props: MonthHeaderProps) {
  const renderSingleMonth = () => {
    return (
      <div className={"month-header q" + props.quarterOfYear} id={props.monthOfYear}>
        <div className="single-month-switcher">
          <h2 id="prev-month" onClick={props.handleMonthChange}>
            {"<"}
          </h2>
          <h2 className="single-month-name">
            {props.monthName} {props.calendarYear}
          </h2>
          <h2 id="next-month" onClick={props.handleMonthChange}>
            {">"}
          </h2>
        </div>
      </div>
    );
  };
  const renderMultiMonth = () => {
    return (
      <div id={props.monthOfYear} >
        <div className={"month-header q" + props.quarterOfYear}>
          <h3 className="quarter">Q{props.quarterOfYear}</h3>
          <h3 className="multi-month-name">
            {props.monthName} {props.calendarYear}
          </h3>
          <h3 className="goto-month-arrow">&nbsp;</h3>
        </div>
      </div>
    );
  };

  return (
    <>
      {props.selectedView === CalendaView.month
        ? renderSingleMonth()
        : renderMultiMonth()}
    </>
  );
}

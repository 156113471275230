import React from "react";
import Tippy from "@tippyjs/react";
import { CalendarType } from "./types";
import "tippy.js/dist/tippy.css";

type Props = {
  selectedCalendar: CalendarType;
  selectedFiscalYear: number;
  selectedView: string;
  handleCalendarChange: (e: React.MouseEvent<HTMLElement>) => void;
  handleViewChange: (e: React.MouseEvent<HTMLElement>) => void;
  handleYearChange: (e: React.MouseEvent<HTMLElement>) => void;
}

function Inputs(props: Props) {
  return (
    <div className="inputs">
      <div>
        <div className="multi-button retailer-buttons">
          <Tippy
            content="Switch to Target Calendar"
            disabled={props.selectedCalendar === "target" ? true : false}
          >
            <button
              id="target"
              className={
                props.selectedCalendar !== "target" ? "disabled" : "target"
              }
              onClick={props.handleCalendarChange}
            >
              Target
          </button>
          </Tippy>
          <Tippy
            content="Switch to Wamart Calendar"
            disabled={props.selectedCalendar === "walmart" ? true : false}
          >
            <button
              id="walmart"
              className={
                props.selectedCalendar !== "walmart" ? "disabled" : "walmart"
              }
              onClick={props.handleCalendarChange}
            >
              Walmart
          </button>
          </Tippy>
          <Tippy
            content="Switch to Kroger Calendar"
            disabled={props.selectedCalendar === "kroger" ? true : false}
          >
            <button
              id="kroger"
              className={
                props.selectedCalendar !== "kroger" ? "disabled" : "kroger"
              }
              onClick={props.handleCalendarChange}
            >
              Kroger
          </button>
          </Tippy>
        </div>

        <div className="multi-button">
          <button
            id="multiYear"
            className={
              props.selectedView !== "multiYear" ? "disabled" : "multiYear"
            }
            onClick={props.handleViewChange}
          >
            Three Year
          </button>
          <button
            id="year"
            className={
              props.selectedView !== "year" ? "disabled" : "year"
            }
            onClick={props.handleViewChange}
          >
            One Year
          </button>
          <button
            id="month"
            className={
              props.selectedView !== "month" ? "disabled" : "month"
            }
            onClick={props.handleViewChange}
          >
            One Month
          </button>
        </div>
      </div>


      <div className="year-arrows">
        <h2 id="prev-year" onClick={props.handleYearChange}>
          &#8592;
        </h2>
        <Tippy content="Fiscal Year Ending">
          <h2 className="fiscal-year">FYE {props.selectedFiscalYear}</h2>
        </Tippy>
        <h2 id="next-year" onClick={props.handleYearChange}>
          &#8594;
        </h2>
      </div>
    </div>
  );
}

export default Inputs;

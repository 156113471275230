import {
  RetailCalendarWeek,
  RetailCalendarMonth,
  LastMonthOfYear,
  LastDayOfWeek,
  WeekCalculation,
  WeekGrouping,
} from "retail-calendar";

export interface RetailCalendarMonthCustom extends RetailCalendarMonth {
  month?: Array<number>;
  year?: any;
  calendar?: any;
  selectedCalendar?: CalendarType;
}

export interface RetailCalendar {
  year: number;
  numberOfWeeks: number;
  months: RetailCalendarMonthCustom[];
  weeks: RetailCalendarWeek[];
  calendarYear?: number;
}

export enum WmtCalendarSettings {
  weekCalculation = WeekCalculation.LastDayBeforeEOM,
  weekGrouping = WeekGrouping.Group454,
  lastDayOfWeek = LastDayOfWeek.Friday,
  lastMonthOfYear = LastMonthOfYear.January,
}

export enum CalendarType {
  walmart = "walmart",
  target = "target",
  kroger = "kroger",
}

export enum CalendaView {
  multiYear = "multiYear",
  year = "year",
  month = "month",
}

import React from "react";
import { RetailCalendar, RetailCalendarMonth } from "retail-calendar";
import { CalendarType, CalendaView } from "./types";
import Week from "./Week";
import MonthHeader from "./MonthHeader";
export { };

type MonthProps = {
  month: RetailCalendarMonth;
  year: number;
  calendar: RetailCalendar;
  handleViewChange: any;
  handleMonthChange?: any;
  selectedView: CalendaView;
  selectedCalendar: CalendarType;
};

function Month(props: MonthProps) {

  const weeks = [...props.month.weeks];
  //console.log(weeks)

  const months = [
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
  ];

  const weekDays = {
    walmart: ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
    target: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  };

  if (props.calendar.numberOfWeeks === 53 && props.month.monthOfYear === 12) {
    weeks.push(props.calendar.weeks[52]);
  }

  const renderWeeks = () => {
    return weeks.map(
      (item: {
        gregorianStartDate: Date;
        gregorianEndDate: Date;
        weekOfYear: number;
        weekOfMonth: number;
      }) => (
        <Week
          key={item.weekOfYear || 53}
          startDate={item.gregorianStartDate}
          endDate={item.gregorianEndDate}
          weekOfYear={item.weekOfYear}
          numberOfWeeksInMonth={props.month.numberOfWeeks}
          weekOfMonth={item.weekOfMonth}
        />
      )
    );
  };

  function renderDayLabels() {
    const days =
      props.selectedCalendar === CalendarType.walmart
        ? weekDays.walmart
        : weekDays.target;
    return days.map((day) => {
      return (
        <span className="day-label" key={day}>
          {day}
        </span>
      );
    });
  }

  return (
    <div className="month-container">
      <MonthHeader
        monthOfYear={props.month.monthOfYear.toString()}
        calendarYear={props.month.weeks[1].gregorianEndDate.getFullYear()}
        monthName={months[props.month.monthOfYear - 1]}
        handleViewChange={props.handleViewChange}
        handleMonthChange={props.handleMonthChange}
        selectedView={props.selectedView}
        quarterOfYear={props.month.quarterOfYear}
      />
      <div>
        <div className="day-label-container">
          <span className="logo">W</span>
          {renderDayLabels()}
        </div>
      </div>
      {renderWeeks()}
    </div>
  );
}

export default Month;

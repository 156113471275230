import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";

export default function Footer() {
  const [loading, setLoading] = useState(true);
  const [footerSrc, setFooterSrc] = useState("");

  useEffect(() => {
    async function getFooter() {
      const res = await fetch("/.netlify/functions/footer");
      if (res.ok) {
        const json = await res.json();
        setLoading(false);
        setFooterSrc(json.msg);
        setTimeout(() => {
          //@ts-ignore
          document.querySelector("iframe").setAttribute("scrolling", "no");
        }, 2500);
      }
    }
    getFooter();
  }, []);

  return (
    <div id="footer-iframe">
      {loading === false && (
        <Frame>
          <div dangerouslySetInnerHTML={{ __html: footerSrc }}></div>
        </Frame>
      )}
    </div>
  );
}

import React from "react";

type CurrentWeekProps = {
    selectedCalendar: string;
    handleYearChange: (e: React.MouseEvent<HTMLElement>) => void;
    currWeek: any;
};

export default function CurrentWeek(props: CurrentWeekProps) {
    let retailerName = props.selectedCalendar.charAt(0).toUpperCase() + props.selectedCalendar.slice(1);
    return (
        <div id="current-week" className="current-week" onClick={props.handleYearChange} >
            <span className="today"> Today </span>
            <span className="date"> {retailerName} Week {props.currWeek} - {new Date().toLocaleDateString("en-US")} </span>
        </div>
    )
}

import React, { useState } from "react";
import { RetailCalendar, RetailCalendarFactory } from "retail-calendar";
import Month from "./Month";
import Year from "./Year";
import Inputs from "./Inputs";
import CurrentWeek from "./CurrentWeek";
import calendarSettings from "./calendarSettings";
import { CalendaView, CalendarType } from "./types";


export default function Calendar() {
  //Default to walmart calendar
  const [selectedCalendar, setSelectedCalendar] = useState<CalendarType>(
    CalendarType.walmart
  );
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<number>(getCurrFiscalYear());

  //Default selected view state to One Year
  const [selectedView, setSelectedView] = useState<CalendaView>(
    CalendaView.year
  );

  //Create a calendar for current year and selected year
  const calendarSetting = calendarSettings.find(setting => setting.retailer === selectedCalendar);
  let currYearCalendar: any;
  let selectedYearCalendar: any
  if (calendarSetting) {
    currYearCalendar = new RetailCalendarFactory(
      {
        weekCalculation: calendarSetting.weekCalculation,
        weekGrouping: calendarSetting.weekGrouping,
        lastDayOfWeek: calendarSetting.lastDayOfWeek,
        lastMonthOfYear: calendarSetting.lastMonthOfYear,
      },
      getCurrFiscalYear() - 1
    );
    selectedYearCalendar = new RetailCalendarFactory(
      {
        weekCalculation: calendarSetting.weekCalculation,
        weekGrouping: calendarSetting.weekGrouping,
        lastDayOfWeek: calendarSetting.lastDayOfWeek,
        lastMonthOfYear: calendarSetting.lastMonthOfYear,
      },
      selectedFiscalYear - 1
    );
  }

  //Get the current week and month
  //There is a bug here when target and kroger are in different fiscal years than walmart
  function getCurrents(cal: RetailCalendar) {
    const todayTime = new Date().getTime();
    let currWeek;
    let currMonth;
    const allWeeks = cal.months.map((m: { weeks: any; }) => {
      return m.weeks;
    })
    for (let i = 0; i < allWeeks.length; i++) {
      for (let j = 0; j < allWeeks[i].length; j++) {
        // console.log("today time: " + todayTime);
        // console.log("gstartdate: " + allWeeks[i][j].gregorianStartDate.getTime());
        // console.log("genddate: " + allWeeks[i][j].gregorianEndDate.getTime());
        if (todayTime >= allWeeks[i][j].gregorianStartDate.getTime() && todayTime <= allWeeks[i][j].gregorianEndDate.getTime()) {
          currWeek = allWeeks[i][j].weekOfYear + 1
          currMonth = allWeeks[i][j].monthOfYear - 1
          return {
            currMonth,
            currWeek
          }
        }
      }
      if (currWeek !== undefined) {
        break;
      }
    }
  }

  //Assign current week and month
  let currents: any = getCurrents(currYearCalendar);
  let { currMonth, currWeek } = currents;

  //Set selected month state to curr month
  const [selectedMonth, setSelectedMonth] = useState<number>(currMonth);

  // Change calendar to the id of the input button
  const handleCalendarChange = (e: React.MouseEvent<HTMLElement>) => {
    const selectedValue = e.currentTarget.id;
    //@ts-ignore
    setSelectedCalendar(CalendarType[selectedValue]);
  };


  const handleMonthChange = (e: React.MouseEvent<HTMLElement>) => {
    //Handle month change if in single month view
    if (e.currentTarget.id === "next-month") {
      if (selectedMonth < 11) {
        setSelectedMonth(selectedMonth + 1);
      } else {
        setSelectedMonth(0);
        setSelectedFiscalYear(selectedFiscalYear + 1);
      }
    } else if (e.currentTarget.id === "prev-month") {
      if (selectedMonth > 0) {
        setSelectedMonth(selectedMonth - 1);
      } else {
        setSelectedMonth(11);
        setSelectedFiscalYear(selectedFiscalYear - 1);
      }
    }
  };

  const handleYearChange = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.id === "next-year") {
      setSelectedFiscalYear(selectedFiscalYear + 1);
    } else if (e.currentTarget.id === "prev-year") {
      setSelectedFiscalYear(selectedFiscalYear - 1);
      //Handle year and month change if current week span is clicked
    } else if (e.currentTarget.id === "current-week") {
      setSelectedFiscalYear(getCurrFiscalYear());
      setSelectedMonth(currMonth)
    }
    //If a condition isn't met for whatever reason, set year back to current year
    else {
      setSelectedFiscalYear(getCurrFiscalYear());
    }
  };

  //Change view based on id of input button
  const handleViewChange = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.id === "multiYear") {
      setSelectedView(CalendaView.multiYear);
    } else if (e.currentTarget.id === "month") {
      setSelectedView(CalendaView.month);
      setSelectedMonth(currMonth);
    } else {
      setSelectedView(CalendaView.year)
    }
  };

  function getCurrFiscalYear() {
    const today = new Date();
    if (today.getMonth() > 0 || today.getDate() > 25) {
      return today.getFullYear() + 1;
    } else {
      return today.getFullYear();
    }
  }

  const renderMultiYearView = () => {
    return (
      <div>
        <Inputs
          selectedCalendar={selectedCalendar}
          selectedFiscalYear={selectedFiscalYear}
          selectedView={selectedView}
          handleCalendarChange={handleCalendarChange}
          handleYearChange={handleYearChange}
          handleViewChange={handleViewChange}
        />

        <CurrentWeek
          handleYearChange={handleYearChange}
          selectedCalendar={selectedCalendar}
          currWeek={currWeek}
        />
        <div className="multi-year">
          <Year
            year={selectedFiscalYear - 2}
            handleViewChange={handleViewChange}
            selectedView={selectedView}
            selectedCalendar={selectedCalendar}
          />

          <Year
            year={selectedFiscalYear - 1}
            handleViewChange={handleViewChange}
            selectedView={selectedView}
            selectedCalendar={selectedCalendar}
          />

          <Year
            year={selectedFiscalYear}
            handleViewChange={handleViewChange}
            selectedView={selectedView}
            selectedCalendar={selectedCalendar}
          />
        </div>
      </div>
    );
  };

  const renderYearView = () => {
    return (
      <div>
        <Inputs
          selectedCalendar={selectedCalendar}
          selectedFiscalYear={selectedFiscalYear}
          selectedView={selectedView}
          handleCalendarChange={handleCalendarChange}
          handleYearChange={handleYearChange}
          handleViewChange={handleViewChange}
        />

        <CurrentWeek
          handleYearChange={handleYearChange}
          selectedCalendar={selectedCalendar}
          currWeek={currWeek}
        />

        <Year
          year={selectedFiscalYear - 1}
          handleViewChange={handleViewChange}
          selectedView={selectedView}
          selectedCalendar={selectedCalendar}
        />
      </div>
    );
  };

  const renderMonthView = () => {
    return (
      <div>
        <Inputs
          selectedCalendar={selectedCalendar}
          selectedFiscalYear={selectedFiscalYear}
          selectedView={selectedView}
          handleCalendarChange={handleCalendarChange}
          handleYearChange={handleYearChange}
          handleViewChange={handleViewChange}
        />
        <CurrentWeek
          handleYearChange={handleYearChange}
          selectedCalendar={selectedCalendar}
          currWeek={currWeek}
        />

        <div className="single-month">
          <div>
            <Month
              month={selectedYearCalendar.months[selectedMonth]}
              year={selectedFiscalYear - 1}
              calendar={selectedYearCalendar}
              selectedView={selectedView}
              handleViewChange={handleViewChange}
              handleMonthChange={handleMonthChange}
              selectedCalendar={selectedCalendar}
            />
          </div>
        </div>
      </div>
    );
  };


  if (selectedView === CalendaView.year) return renderYearView();
  else if (selectedView === CalendaView.multiYear) return renderMultiYearView();
  else if (selectedView === CalendaView.month) return renderMonthView();
  else return <h1> Unknown View </h1>;
}